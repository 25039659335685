exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-enterprise-data-js": () => import("./../../../src/pages/approach/enterprise-data.js" /* webpackChunkName: "component---src-pages-approach-enterprise-data-js" */),
  "component---src-pages-approach-from-abap-to-btp-js": () => import("./../../../src/pages/approach/from-abap-to-btp.js" /* webpackChunkName: "component---src-pages-approach-from-abap-to-btp-js" */),
  "component---src-pages-approach-index-js": () => import("./../../../src/pages/approach/index.js" /* webpackChunkName: "component---src-pages-approach-index-js" */),
  "component---src-pages-approach-keep-the-core-clean-js": () => import("./../../../src/pages/approach/keep-the-core-clean.js" /* webpackChunkName: "component---src-pages-approach-keep-the-core-clean-js" */),
  "component---src-pages-book-exploration-and-advisory-session-js": () => import("./../../../src/pages/book-exploration-and-advisory-session.js" /* webpackChunkName: "component---src-pages-book-exploration-and-advisory-session-js" */),
  "component---src-pages-book-maturity-assessment-js": () => import("./../../../src/pages/book-maturity-assessment.js" /* webpackChunkName: "component---src-pages-book-maturity-assessment-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-and-privacy-js": () => import("./../../../src/pages/cookies-and-privacy.js" /* webpackChunkName: "component---src-pages-cookies-and-privacy-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-services-btp-js": () => import("./../../../src/pages/services/btp.js" /* webpackChunkName: "component---src-pages-services-btp-js" */),
  "component---src-pages-services-integrations-and-extensions-cloud-platform-integration-js": () => import("./../../../src/pages/services/integrations-and-extensions/cloud-platform-integration.js" /* webpackChunkName: "component---src-pages-services-integrations-and-extensions-cloud-platform-integration-js" */),
  "component---src-pages-services-integrations-and-extensions-enterprise-data-roadmap-js": () => import("./../../../src/pages/services/integrations-and-extensions/enterprise-data-roadmap.js" /* webpackChunkName: "component---src-pages-services-integrations-and-extensions-enterprise-data-roadmap-js" */),
  "component---src-pages-services-integrations-and-extensions-index-js": () => import("./../../../src/pages/services/integrations-and-extensions/index.js" /* webpackChunkName: "component---src-pages-services-integrations-and-extensions-index-js" */),
  "component---src-pages-services-integrations-and-extensions-solid-integration-foundation-js": () => import("./../../../src/pages/services/integrations-and-extensions/solid-integration-foundation.js" /* webpackChunkName: "component---src-pages-services-integrations-and-extensions-solid-integration-foundation-js" */),
  "component---src-pages-services-rapid-app-development-index-js": () => import("./../../../src/pages/services/rapid-app-development/index.js" /* webpackChunkName: "component---src-pages-services-rapid-app-development-index-js" */),
  "component---src-pages-services-rapid-app-development-lower-app-development-tco-js": () => import("./../../../src/pages/services/rapid-app-development/lower-app-development-tco.js" /* webpackChunkName: "component---src-pages-services-rapid-app-development-lower-app-development-tco-js" */),
  "component---src-pages-services-rapid-app-development-sap-app-development-js": () => import("./../../../src/pages/services/rapid-app-development/sap-app-development.js" /* webpackChunkName: "component---src-pages-services-rapid-app-development-sap-app-development-js" */),
  "component---src-pages-services-rapid-app-development-solid-app-development-foundation-js": () => import("./../../../src/pages/services/rapid-app-development/solid-app-development-foundation.js" /* webpackChunkName: "component---src-pages-services-rapid-app-development-solid-app-development-foundation-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-use-cases-aperam-js": () => import("./../../../src/pages/use-cases/aperam.js" /* webpackChunkName: "component---src-pages-use-cases-aperam-js" */),
  "component---src-pages-use-cases-event-driven-lead-to-cash-js": () => import("./../../../src/pages/use-cases/event-driven-lead-to-cash.js" /* webpackChunkName: "component---src-pages-use-cases-event-driven-lead-to-cash-js" */),
  "component---src-pages-use-cases-wifi-request-js": () => import("./../../../src/pages/use-cases/wifi-request.js" /* webpackChunkName: "component---src-pages-use-cases-wifi-request-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */)
}

